<template>
  <b-row align-h="center">
    <b-col lg="6" class="inc-accounting-entries-divisor" v-if="showConstructor">
      <!-- 
        ################
        ###   DEBE   ###
        ################
      -->      
      <!-- ABM DEBE -->
      <b-row>
        <b-col md="7" :key="'form_debe_' + debe.account_id">                    
          <FindObject render="search"
                      type="accountingAccountsImputation" 
                      @select-object="loadDebeAccounting($event)" 
                      :valueID="debe.account_id"
                      :where="conditionAccountingAccountsDebe" />
        </b-col>

        <b-col md="4">                    
          <b-form-group label="Monto" description="Monto asignado">    
            <b-input-group size="sm" prepend="$">
              <b-form-input v-model="debe.amount" 
                            type="number"                                   
                            min="0"  
                            step="0.01">
              </b-form-input>            
            </b-input-group>                      
          </b-form-group>
        </b-col>         
        <b-col md="1" class="p-0">         
            <b-button size="sm" 
                      variant="success" 
                      class="inc-accounting-entries-item-add" 
                      v-b-tooltip.hover 
                      title="Agregar"
                      @click="addDebe()">
              <b-icon icon="plus-circle" aria-hidden="true"></b-icon>
            </b-button>           
        </b-col>
      </b-row>

      <!-- LIST DEBE -->
      <b-row>
        <b-col>                    
          <b-table-simple hover small responsive v-if="debe.detail.length">             
            <b-thead head-variant="dark">                                          
              <b-tr>                      
                <b-th width="35%" class="text-left">Cuenta Cotable</b-th>
                <b-th width="15%" class="text-right">Debe</b-th>
                <b-th width="15%" class="text-right">Haber</b-th>
                <b-th width="15%" class="text-center"></b-th>
              </b-tr>
            </b-thead>      
            
            <b-tbody v-for="(item, index) in debe.detail" :key="'list_debe' + index"> 
              <b-tr>                                       
                <b-td class="text-left align-middle">
                  <span v-if="item.account">{{item.account.name}}</span>
                </b-td>
                <b-td class="text-right align-middle">
                  {{ Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(item.amount) }}
                </b-td>
                <b-td class="text-right align-middle">
                  
                </b-td>                
                <b-td class="text-center align-middle">
                  <b-button size="sm" 
                            variant="danger"                                       
                            @click="removeDebe(index)"
                            v-if="!item.disabled">
                    <b-icon icon="x" aria-hidden="true"></b-icon>
                  </b-button>                               
                </b-td>
              </b-tr>
            </b-tbody>                              
          </b-table-simple>   
          <b-table-simple hover small responsive v-else>             
            <b-thead head-variant="dark">                                          
              <b-tr>                      
                <b-th width="35%" class="text-left">Cuenta Cotable</b-th>
                <b-th width="15%" class="text-right">Debe</b-th>
                <b-th width="15%" class="text-right">Haber</b-th>
                <b-th width="15%" class="text-center"></b-th>
              </b-tr>
            </b-thead>                
          </b-table-simple>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <hr>
        </b-col>
      </b-row>

      <!-- 
        #################
        ###   HABER   ###
        #################
      -->
      <!-- ABM HABER -->
      <b-row>
        <b-col md="7" :key="'form_haber' + haber.account_id">                    
          <FindObject render="search"
                      type="accountingAccountsImputation" 
                      @select-object="loadHaberAccounting($event)" 
                      :valueID="haber.account_id"
                      :where="conditionAccountingAccountsHaber" />
        </b-col>

        <b-col md="4">                    
          <b-form-group label="Monto" description="Monto asignado">    
            <b-input-group size="sm" prepend="$">
              <b-form-input v-model="haber.amount" 
                            type="number"                                   
                            min="0"  
                            step="0.01">
              </b-form-input>            
            </b-input-group>                      
          </b-form-group>
        </b-col>         
        <b-col md="1" class="p-0">         
            <b-button size="sm" 
                      variant="success" 
                      class="inc-accounting-entries-item-add" 
                      v-b-tooltip.hover 
                      title="Agregar"
                      @click="addHaber()">
              <b-icon icon="plus-circle" aria-hidden="true"></b-icon>
            </b-button>           
        </b-col>
      </b-row>

      <!-- LIST HABER -->
      <b-row>
        <b-col>                    
          <b-table-simple hover small responsive v-if="haber.detail.length">             
            <b-thead head-variant="dark">                                          
              <b-tr>                      
                <b-th width="35%" class="text-left">Cuenta Cotable</b-th>
                <b-th width="15%" class="text-right">Debe</b-th>
                <b-th width="15%" class="text-right">Haber</b-th>
                <b-th width="15%" class="text-center"></b-th>
              </b-tr>
            </b-thead>      
            
            <b-tbody v-for="(item, index) in haber.detail" :key="'list_haber' + index"> 
              <b-tr>                                       
                <b-td class="text-left align-middle">
                  <span v-if="item.account">{{item.account.name}}</span>
                </b-td>
                <b-td class="text-right align-middle">
                  
                </b-td>                                
                <b-td class="text-right align-middle">
                  {{ Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(item.amount) }}
                </b-td>
                <b-td class="text-center align-middle">
                  <b-button size="sm" 
                            variant="danger"                                       
                            @click="removeHaber(index)"
                            v-if="!item.disabled">
                    <b-icon icon="x" aria-hidden="true"></b-icon>
                  </b-button>                               
                </b-td>
              </b-tr>
            </b-tbody>                              
          </b-table-simple>   
          <b-table-simple hover small responsive v-else>             
            <b-thead head-variant="dark">                                          
              <b-tr>                      
                <b-th width="35%" class="text-left">Cuenta Cotable</b-th>
                <b-th width="15%" class="text-right">Debe</b-th>
                <b-th width="15%" class="text-right">Haber</b-th>
                <b-th width="15%" class="text-center"></b-th>
              </b-tr>
            </b-thead>                
          </b-table-simple>
        </b-col>
      </b-row>
    </b-col>

    <b-col lg="6">
      <label class="inc-accounting-entries-title">{{this.title}}</label>
      <b-table-simple hover small responsive>             
        <b-thead head-variant="dark">                                          
          <b-tr>                      
            <b-th width="40%" class="text-left">Cuenta Cotable</b-th>
            <b-th width="20%" class="text-right">Debe</b-th>
            <b-th width="20%" class="text-right">Haber</b-th>
          </b-tr>
        </b-thead>                
        <b-tbody> 
          <b-tr v-for="(item, index) in debe.detail" :key="'entry_debe' + index">                                       
            <b-td class="text-left align-middle">
              <span v-if="item.account">{{item.account.name}}</span>
            </b-td>
            <b-td class="text-right align-middle">
              {{ Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(item.amount) }}
            </b-td>
            <b-td class="text-right align-middle">
              
            </b-td>
          </b-tr>
        
          <b-tr v-for="(item, index) in haber.detail" :key="'entry_haber' + index">                                       
            <b-td class="text-left align-middle">
              <span v-if="item.account" class="ml-5">{{item.account.name}}</span>
            </b-td>
            <b-td class="text-right align-middle">
              
            </b-td>                                
            <b-td class="text-right align-middle">
              {{ Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(item.amount) }}                          
            </b-td>
          </b-tr>
        </b-tbody>                              
      </b-table-simple>
      <b-alert show variant="danger" v-if="!validAsiento">
        <h4 class="alert-heading">Asiento con Diferencias</h4>
        <p>Revise la confección del asiento y corrija la diferencia para continuar.</p>
      </b-alert>
      <b-alert show variant="success" v-else>
        <h4 class="alert-heading">Asiento OK</h4>
        <p>Puede continuar con el proceso.</p>
      </b-alert>      
    </b-col>
  </b-row>
</template>
<script>  
  import serviceAPI from './services'
  import ErrorToken from '@/handler/errorToken'
  import Helper from '@/handler/helper'
  import FindObject from '@/components/inc/find/findObject'  

  export default {
    props: {
      typeDebe: {
        type: String,
        default: '',
        required: true,
      },
      typeHaber: {
        type: String,
        default: '',
        required: true,
      }, 
      defaultDebe: {
        type: Array,
        default: [],
        required: true,
      },
      defaultHaber: {
        type: Array,
        default: [],
        required: true,
      },       
      currency: {
        type: Object,
        default: null
      },
      reference: {
        type: String,
        default: '',
        required: true,
      },
      amountTotal: {
        type: Number,
        default: 0,
        required: true,
      },          
      showConstructor: {
        type: Boolean,
        default: false,
      } 
    },
    components: {
      FindObject,      
    },    
    data: () => {
      return { 
        debe: {
          account: null,
          account_id: 0,
          amount: 0,
          detail: [],
        },   
        haber: {
          account: null,
          account_id: 0,
          amount: 0,
          detail: [],
        },
        title: '',
      }
    }, 
    computed: {
      getCurrency() {
        if(this.currency) {
          return this.currency.name
        } else {
          return 'ARS'
        }        
      },

      conditionAccountingAccountsDebe(){
        if(this.typeDebe) {
          return [
            {field: 'active', condition: true},
            {field: 'type', condition: this.typeDebe}
          ];
        } else {
          return [
            {field: 'active', condition: true},
          ]
        }
      },        
      conditionAccountingAccountsHaber(){
        if(this.typeHaber) {
          return [
            {field: 'active', condition: true},
            {field: 'type', condition: this.typeHaber}
          ];
        } else {
          return [
            {field: 'active', condition: true},
          ]
        }
      },

      getTotalDebe() {
        var sum = 0
        this.debe.detail.forEach(element => {
          sum = sum + parseFloat(element.amount)
        });
        return sum        
      },
      getTotalHaber() {
        var sum = 0
        this.haber.detail.forEach(element => {
          sum = sum + parseFloat(element.amount)
        });
        return sum        
      },
      validAsiento() {
        if(parseFloat(this.getTotalDebe.toFixed(2)) == parseFloat(this.amountTotal.toFixed(2)) &&
           parseFloat(this.getTotalHaber.toFixed(2)) == parseFloat(this.amountTotal.toFixed(2))) {
            
          return true
        } else {
          return false
        }                    
      }
    },   
    mounted() {
      this.titleEntrie()
      this.loadAsiento()
    }, 
    methods: {
      titleEntrie() {
        this.title = 'Asiento Contable: ' + this.reference
      },

      loadDebeAccounting(object) {
        if(object){
          this.debe.account = object
          this.debe.account_id = object.id                      
        } else {
          this.debe.account = null
          this.debe.account_id = 0   
        }        
      },
      addDebe() {
        if(!this.debe.account_id) {
          this.$awn.alert("No se cargó la Cuenta Contable");
          return false;          
        }

        if(!parseFloat(this.debe.amount)) {
          this.$awn.alert("No se cargó el Importe para la Cuenta Contable");
          return false;          
        }        

        var sum = parseFloat(this.debe.amount)
        this.debe.detail.forEach(element => {
          sum = sum + parseFloat(element.amount)
        });
        if(sum>parseFloat(this.amountTotal.toFixed(2))) {
          this.$awn.alert("El valor ingresado no puede ser mayor al monto total de la operación");
          return false;                    
        }

        this.debe.detail.push({
          account: this.debe.account,
          account_id: this.debe.account_id,
          amount: this.debe.amount,
        })        

        this.debe.account = null
        this.debe.account_id = 0
        this.debe.amount = 0

        this.getAsiento()
      },
      removeDebe(index) {
        this.debe.detail.splice(index, 1)

        this.getAsiento()
      },

      loadHaberAccounting(object) {
        if(object){
          this.haber.account = object
          this.haber.account_id = object.id                      
        } else {
          this.haber.account = null
          this.haber.account_id = 0   
        }        
      },
      addHaber() {
        if(!this.haber.account_id) {
          this.$awn.alert("No se cargó la Cuenta Contable");
          return false;          
        }

        if(!parseFloat(this.haber.amount)) {
          this.$awn.alert("No se cargó el Importe para la Cuenta Contable");
          return false;          
        }        

        var sum = parseFloat(this.haber.amount)
        this.haber.detail.forEach(element => {
          sum = sum + parseFloat(element.amount)
        });        
        if(sum>parseFloat(this.amountTotal.toFixed(2))) {
          this.$awn.alert("El valor ingresado no puede ser mayor al monto total de la operación");
          return false;                    
        }

        this.haber.detail.push({
          account: this.haber.account,
          account_id: this.haber.account_id,
          amount: this.haber.amount,
        })        

        this.haber.account = null
        this.haber.account_id = 0
        this.haber.amount = 0

        this.getAsiento()
      },
      removeHaber(index) {
        this.haber.detail.splice(index, 1)

        this.getAsiento()
      },   
      
      loadAsiento() {
        if(this.defaultDebe) {
          this.defaultDebe.forEach(element => {  
            if(parseFloat(element.amount)) {

              var statusAdd = true
              this.debe.detail.forEach(element1 => {
                if( element.account.id == element1.account_id && 
                    element.disabled == element1.disabled) {

                  element1.amount = parseFloat(element1.amount) + parseFloat(element.amount)
                  statusAdd = false
                }
              });

              if(statusAdd) {
                this.debe.detail.push({
                  account: element.account,
                  account_id: element.account.id,
                  amount: parseFloat(element.amount),
                  disabled: element.disabled,
                })           
              } 
              
            }   
          });
        }

        if(this.defaultHaber) {
          this.defaultHaber.forEach(element => {
            if(parseFloat(element.amount)) {

              var statusAdd = true
              this.haber.detail.forEach(element1 => {              
                if( element.account.id == element1.account_id && 
                    element.disabled == element1.disabled) {

                  element1.amount = parseFloat(element1.amount) + parseFloat(element.amount)
                  statusAdd = false
                }
              });

              if(statusAdd) {            
                this.haber.detail.push({
                  account: element.account,
                  account_id: element.account.id,
                  amount: parseFloat(element.amount),
                  disabled: element.disabled,
                })             
              }  

            }
          });
        }

        this.getAsiento()
      },
      getAsiento() {
        if(this.validAsiento) {
          var asiento = []
          this.debe.detail.forEach(element => {
            asiento.push({
              account: element.account,
              account_id: element.account_id,
              amount: element.amount,              
              type: 'debe',
            })  
          });
          this.haber.detail.forEach(element => {
            asiento.push({
              account: element.account,
              account_id: element.account_id,
              amount: element.amount,              
              type: 'haber',              
            })  
          });          
          this.$emit('getAsiento', asiento) 
        } else {
          this.$emit('getAsiento', null) 
        }
      }
    }
  }
</script>
<style scoped>
  .inc-accounting-entries-item-add {
    margin-top: 30px;
  }
  .inc-accounting-entries-divisor {
    border-right-width: 1px;
    border-right-color: #e4e7ea;
    border-right-style: solid;    
    margin-bottom: 10px;
  }  
  .inc-accounting-entries-title {
    font-size: 14px;
    font-weight: bold;
  }
</style>